import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Smarthome/IFTTT_Applets/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemPerm';
import PrimaryBox from 'components/Web_User_Interface/1440p_Series/Smarthome/IFTTT_Applets/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Smarthome Menu // INSTAR IFTTT Applets",
  "path": "/Web_User_Interface/1440p_Series/Smarthome/IFTTT_Applets/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "On the IFTTT Platform, we provided a service named INSTAR. The INSTAR Applets provides a way for you to control some settings of your INSTAR Camera or INSTAR Cloud with IFTTT. You can connect INSTAR to Location and Date & Time triggers and receive Notifications and more. For example, you can connected the INSTAR Applets with the IFTTT Location service and to turn off the alarm when you arrive at home. You can also use the INSTAR Applets to build your own automation connecting them to other third-party Applets.",
  "image": "./WebUI_1440p_SearchThumb_Smarthome_Alexa.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Smarthome_IFTTT.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Smarthome Menu // INSTAR IFTTT Applets' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='On the IFTTT Platform, we provided a service named INSTAR. The INSTAR Applets provides a way for you to control some settings of your INSTAR Camera or INSTAR Cloud with IFTTT. You can connect INSTAR to Location and Date & Time triggers and receive Notifications and more. For example, you can connected the INSTAR Applets with the IFTTT Location service and to turn off the alarm when you arrive at home. You can also use the INSTAR Applets to build your own automation connecting them to other third-party Applets.' image='/images/Search/WebUI_1440p_SearchThumb_Smarthome_IFTTT.png' twitter='/images/Search/WebUI_1440p_SearchThumb_Smarthome_IFTTT.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Smarthome/IFTTT_Applets/' locationFR='/fr/Web_User_Interface/1440p_Series/Smarthome/IFTTT_Applets/' crumbLabel="IFTTT" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "smarthome-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#smarthome-menu",
        "aria-label": "smarthome menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Smarthome Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/372cf98fc44600f2f786d2daec7c0d27/86a1e/1440p_Settings_Smarthome_IFTTT.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACkUlEQVQ4y6WTS28SURiG+RMmxIRLiy1zoaUtVIowtzMzWFrKwABzgZqaqC0QtI0ajRpiF9bEhV2qSa2autCNmhjjTl2Y+LdeMweGUrvRuHhyzpnJ98z7nXMmwPM8OI4Dx/OYikWRzBWxevsI5PrhKZTeIbQbhyjfOUIio+Fs8AyWsllkMhkEg0FEIhEEfCHLcpieOgdJK6HV24Xd6cPq9Ono462dbh/Nbh95eRnzcylkswKWlnJIpc5jdmYOAZqO48AwDOJxBkQmaNp1rDsNtJw61t3GiEuuRalV1mBULBBShJBXIAoEiqwjncoMErIsS4nH41AUHc1WC47bhO04cByXYnujO5g3Ghas2hUsqyUQhUASCZV6KQODdj0hQ4WEaHBpoXMC27bp6L2r1+to15tomQYKugRZUiEK6kD4Z0JdU+G4zqjYlzcsi1IzTVQMA7vdBnqbBZRWJAg5GbKoIn0s5EZClag0Qa1WG1GtVmAaqzCrBpYrBopGFXv9NjqXZehEgizK0BQFi+nF8ZaHQkUbSqp0NKsVlE0baxs3YVpXsVMo4lqxhHf7e3jca0IWcygQGbqqIO0Jj1senLIsEZTLZdrWykoJhmFgq93G9qPnuPvwKV50O3iyuYVvr9/gw4NbuEjyNJ0ie4cyltC7Nh65CwJEQYYkKijqOu7tdPH+YB8vD57h56/v+PrpCJ/fvsKPLx9xcH8bG2UJ+bxI215YSJ9uORaLUSYnY2Di00gmWCRnEnSfk7MzSCZ4up6fnwPPsWCmY2M1kwj46XxhNBod4f1KobBHeDAPhemcEgohHI4gEomeqAkww3S+cGJi4r8I+HvnX2zv4fgX/fVfCzk+AQ+WS4BhWLoPPv8q84S/AQSWB2V8SW+9AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/372cf98fc44600f2f786d2daec7c0d27/e4706/1440p_Settings_Smarthome_IFTTT.avif 230w", "/en/static/372cf98fc44600f2f786d2daec7c0d27/d1af7/1440p_Settings_Smarthome_IFTTT.avif 460w", "/en/static/372cf98fc44600f2f786d2daec7c0d27/7f308/1440p_Settings_Smarthome_IFTTT.avif 920w", "/en/static/372cf98fc44600f2f786d2daec7c0d27/6f791/1440p_Settings_Smarthome_IFTTT.avif 1296w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/372cf98fc44600f2f786d2daec7c0d27/a0b58/1440p_Settings_Smarthome_IFTTT.webp 230w", "/en/static/372cf98fc44600f2f786d2daec7c0d27/bc10c/1440p_Settings_Smarthome_IFTTT.webp 460w", "/en/static/372cf98fc44600f2f786d2daec7c0d27/966d8/1440p_Settings_Smarthome_IFTTT.webp 920w", "/en/static/372cf98fc44600f2f786d2daec7c0d27/0b716/1440p_Settings_Smarthome_IFTTT.webp 1296w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/372cf98fc44600f2f786d2daec7c0d27/81c8e/1440p_Settings_Smarthome_IFTTT.png 230w", "/en/static/372cf98fc44600f2f786d2daec7c0d27/08a84/1440p_Settings_Smarthome_IFTTT.png 460w", "/en/static/372cf98fc44600f2f786d2daec7c0d27/c0255/1440p_Settings_Smarthome_IFTTT.png 920w", "/en/static/372cf98fc44600f2f786d2daec7c0d27/86a1e/1440p_Settings_Smarthome_IFTTT.png 1296w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/372cf98fc44600f2f786d2daec7c0d27/c0255/1440p_Settings_Smarthome_IFTTT.png",
              "alt": "Web User Interface - 1440p Series - Smarthome IFTTT Applets",
              "title": "Web User Interface - 1440p Series - Smarthome IFTTT Applets",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`On the IFTTT Platform, we provided a service named INSTAR. The INSTAR Applets provides a way for you to control some settings of your INSTAR Camera or INSTAR Cloud with IFTTT. You can connect INSTAR to Location and Date & Time triggers and receive Notifications and more. For example, you can connected the INSTAR Applets with the IFTTT Location service and to turn off the alarm when you arrive at home. You can also use the INSTAR Applets to build your own automation connecting them to other third-party Applets.`}</p>
    <PrimaryBox mdxType="PrimaryBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      